:root {
  --color-googlePlus: #d74937;
  --color-tripAdvisor: #4caf50;
  --color-facebook: #3b5999;
  --color-instagram: #e1306c;
}
.hotellela {
  .cls-wrapper {
    height: 100%;
    flex-direction: row-reverse !important;
    justify-content: space-around;
    .hotel-lela-contact-info {
      .Phone-text {
        display: flex;
        text-align: left;
        a {
          color: white !important;
          font-family: Mr Eaves Mod OT !important;
          font-size: 18px;
        }
      }
    }
  }

  .copywrite-msg a {
    color: #b8b8b8 !important;
  }
  .copywrite-msg a:hover {
    color: #fff !important;
  }
  .staticP {
    color: #fff !important;
    font-size: 14px !important;
  }
  .lelatrade {
    color: #fff !important;
    text-align: center;
    p {
      font-size: 17px;
    }
  }
  .Footer-Style-hotellela {
    .goog-te-gadget-simple {
      width: auto !important;
    }
    .hotelela-logo {
      width: 100%;
      height: 115px;
      object-fit: contain;
    }
    .grecaptcha-badge {
      display: none !important;
    }
    .footer-component {
      padding-top: 30px;
      background: url("../assets/images/FooterBg.jpg") #363848;
    }
    .tripIcon {
      width: 133px !important;
      height: 133px !important;
    }
    .list-unstyled-img {
      display: contents;
      li {
        display: grid;
        place-items: center;
      }
    }

    .list-unstyled > li > a:hover {
      color: #fff !important;
    }

    input:focus::placeholder {
      color: transparent;
    }

    .subscribe_wrapper {
      .subscribe-input-text {
        color: #9b9b9b;
        font-family: Angie Sans Std !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 2.28px;
        text-transform: capitalize;
        .btn-subscribe {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          input {
            width: 80%;
            height: 40px;
            padding-left: 20px;
            outline: none;
            border: none;
            border-radius: 0px !important;
            background-color: #fdd2c1;
            font-family: Mr Eaves Mod OT !important;
          }
          button {
            width: 50%;
            border: none;
            outline: none;
            height: 40px;
            border: 1px solid white;
            color: #000;
            font-family: Angie Sans Std !important;
            font-size: 20px;
            font-weight: 400;
            &:hover {
              border-left: 1px solid white;
            }
          }
        }
      }
    }
    .grid-social-icons {
      .grid-icon {
        position: relative;
        display: inline-block;
        transition: all 0.5s ease-in-out;
        z-index: 5;
        svg {
          color: #9b9b9b;
        }
      }
      .grid-icon:hover {
        transform: scale(1.2);
      }
      .GoogleLogo,
      .tripAdvisor,
      .FBlogo,
      .Inlogo {
        transition: all 0.5s ease-in-out;
      }
      .GoogleLogo:hover {
        color: var(--color-googlePlus) !important;
      }
      .tripAdvisor:hover {
        color: var(--color-tripAdvisor) !important;
      }
      .FBlogo:hover {
        color: var(--color-facebook) !important;
      }
      .Inlogo:hover {
        color: var(--color-instagram) !important;
      }
      .tooltip {
        visibility: hidden;
        background-color: #000;
        color: #fff;
        text-align: center;
        border-radius: 4px;
        padding: 5px 10px;
        z-index: 1;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
        font-family: Angie Sans Std !important;
        div {
          position: relative;
          svg {
            position: absolute;
            top: 20px;
            right: 50%;
          }
        }
      }

      .grid-icon:hover .tooltip {
        visibility: visible;
        opacity: 1;
        transform: translateX(-50%) translateY(-35px);
      }
    }

    .goog-te-gadget-simple {
      background-color: #a69585 !important;
      color: #fff !important;
      border: 0.5px solid #fff !important;
    }

    .goog-te-gadget-simple span {
      color: #fff !important;
    }

    .button-ada {
      padding: 12px 20px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #0563ae;
      color: #fff;
      border: none;
      cursor: pointer;
      width: 160px;
    }

    .bdr-hr hr {
      background-color: #d9d9d9;
      width: 90%;
    }

    .grid-guest-suties {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Two equal-width columns */
      gap: 10px; /* Optional gap between columns */
    }

    .copywrite-msg {
      display: flex;
      color: #b8b8b8;
      text-align: justify;
      font-family: Helvetica;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }

    .grid-social-icons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 1px;
      padding: 5px 20px;
      max-width: 500px;
    }

    .links-grid-container .links-row {
      display: flex;
      list-style-type: none;
      width: 100%;
      justify-content: center;
      gap: 20px;
      flex-wrap: wrap;
    }
    .links-row li:not(:last-child) {
      border-right: 1px solid white;
      padding-right: 10px;
      font-size: 15px;
    }

    .links-row a {
      color: #fff;
      font-family: "Mr Eaves Mod OT" !important;
      font-size: 17px;
    }

    .copy-right-bg {
      background: #181c25;
      color: #495062;
    }

    .subscribe_wrapper .subscribe-input-text .btn-subscribe button {
      background: #4e5a73;
      color: #fff;
    }

    @media screen and (min-width: 1200px) {
      .MT30 {
        margin: 31px 0px 0px 0px;
      }
      .desktop-logo {
        height: 50px;
      }
      .copySec {
        margin-bottom: 32px;
      }
      .button-container {
        display: flex;
        gap: 40px;
        justify-content: center;
        align-items: center;
      }

      .subscribe_wrapper .subscribe-input-text .btn-subscribe input {
        height: 40px;
      }
      .subscribe_wrapper .subscribe-input-text .btn-subscribe button {
        width: 150px !important;
        height: 40px;
        font-family: Angie Sans Std !important;
      }
    }
    @media screen and (max-width: 1199px) {
      .cls-wrapper {
        .hotel-lela-contact-info {
          .Phone-text {
            a {
              font-size: 16px;
            }
          }
        }
      }
      .lelatrade {
        width: 90%;
        margin: auto !important;
        text-align: justify;
      }
    }

    @media screen and (max-width: 992px) and (min-width: 768px) {
      .hilton1 {
        margin-right: 25px;
        margin-top: -13px;
        img {
          height: 65px;
        }
      }
      .langbtn_ipad {
        margin-top: 3px !important;
      }
      .subscribe_wrapper {
        .subscribe-input-text {
          color: #9b9b9b;
          font-family: Angie Sans Std !important;
          font-size: 26px !important;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 31.2px */
          letter-spacing: 2.28px;
          text-transform: capitalize;
          .btn-subscribe {
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }

    @media screen and (max-width: 1199px) and (min-width: 993px) {
      .logoSec {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .hilton1 {
        margin-right: 25px;
        margin-top: 2px;
        img {
          height: 65px;
        }
      }
      .logoSec .lang1 {
        margin-right: 15px;
      }
    }
    @media screen and (max-width: 992px) {
      .cls-wrapper {
        flex-direction: column !important;
        height: 100%;
        .hotel-lela-logo {
          order: 1;
        }
        .subscribe_wrapper {
          order: 2;
          margin-top: 25px;
          margin-bottom: 25px;
        }
        .hotel-lela-contact-info {
          order: 3;
          align-items: center;
        }
      }
      .copywrite-msg {
        display: flex;
        flex-direction: column;
        margin: 0px 0 20px 0;
        color: #b8b8b8;
        text-align: justify;
        font-family: Angie Sans Std !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        padding-bottom: 30px;
      }
      .links-grid-container {
        display: none;
      }
      .button-ada {
        padding: 13px 20px;
        text-align: center;
        display: flex !important;
        justify-content: center;
        align-items: center;
        background-color: #0563ae;
        color: #fff;
        border: none;
        cursor: pointer;
        width: 180px;
        margin: 0px 20px;
      }

      .logoSec {
        display: flex;
        margin-bottom: 0px !important;
      }

      .links-row {
        display: none !important;
      }

      input,
      input::placeholder {
        font-size: 20px !important;
      }
      .subscribe_wrapper .subscribe-input-text .btn-subscribe input {
        font-size: 16px !important;
      }

      .grid-social-icons {
        padding: 0px !important;
      }
      .grid-pages-links {
        display: grid;
        grid-template-columns: 1fr !important;
        grid-gap: 1px;
        padding: 5px !important;
        ul li {
          text-align: center !important;
        }
        .list-unstyled-img {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding-left: 0px;
          li {
            padding: 10px;
          }
        }
      }

      .subscribe_wrapper {
        width: 100% !important;
        .subscribe-input-text {
          width: 90%;
          margin: auto !important;
          text-align: center !important;
          .btn-subscribe {
            margin-top: 28px !important;

            button {
              width: 220px !important;
              border: 1px solid white;
              color: #fff;
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .logoSec .hilton1 .desktop-logo {
        margin-top: 0rem !important;
      }
      .footer-component {
        padding-top: 30px;
        background: url("../assets/images/FooterBg.jpg") #363848;
        background-position: 100% -5%;
        background-repeat: no-repeat;
      }
      .copy-right-line1 {
        display: flex;
        flex-direction: column;
        font-size: 14px;
      }
      .hotelela-logo {
        width: 45% !important;
      }
      .goog-te-gadget-simple {
        width: 180px;
      }
      .Accbtn {
        margin-top: 24px;
      }
      .hilton1 {
        display: flex;
        justify-content: center;
        img {
          width: 140px !important;
        }
      }
      .subscribe_wrapper .subscribe-input-text .btn-subscribe button {
        width: 170px !important;
        border: 1px solid white;
        font-size: 14px !important;
      }
      .subscribe_wrapper .subscribe-input-text .btn-subscribe input {
        width: 100%;
        font-size: 12px !important;
      }
      input,
      input::placeholder {
        font-size: 13px !important;
      }
    }
  }
}
