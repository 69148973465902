.Footer-Style-hotelCurrent {
  .grecaptcha-badge {
    display: none !important;
  }
  .hc-subscription {
    background-image: url("../assets/images/hc-footer-subscription-bg.webp");
    .footerTitle {
      color: #fff;
      text-align: center;
      // font-family: Rufina !important;
      font-size: 35px;
      font-style: normal;
      font-weight: 400;
      line-height: 123.5%;
      margin-bottom: 5px;
    }

    .footerSubTitle {
      color: #fff;
      text-align: center;
      // font-family: Rufina !important;
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 123.5%;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .subscribe_wrapper {
      .subscribe-input-text {
        width: 40%;
        #subscriber_email {
          border-radius: 0px !important;
          padding: 1.375rem 0.75rem !important;
        }
        input:focus::placeholder {
          color: transparent;
        }

        .footer-hc-btn {
          width: 30%;
          border: none;
          background: #7e9f36;
          color: white;
        }
      }
    }
  }
  .hc-otherDetails {
    background-image: url("../assets/images/hc-footer-bg-image.webp");
    .hc-site-links {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .site1,
      .site2,
      .site3,
      .site4 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        li {
          list-style: none;
        }
        a {
          color: #000;
          font-family: Readex Pro !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: 0.2px;
        }
      }
      .site4 {
        div {
          display: flex;
        }
        .lang-btn {
          .skiptranslate {
            background: #fff;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
            .goog-te-gadget-simple {
              display: flex;
              justify-content: center;
              height: unset;
              width: unset;
              padding: 10px 20px !important;
            }
          }
        }
        .button-ada {
          padding: 8px 20px;
          text-align: center;
          background-color: #0563ae;
          color: #fff;
          border: none;
          cursor: pointer;
          display: flex !important;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
        }
        .button-modify-reservation {
          background: #017aa5;
          padding: 8px 20px;
          text-align: center;
          color: #fff;
          border: none;
          cursor: pointer;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .hc-social-section {
      .socialLinks {
        background: #fff;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-around;
        align-items: center;
        .social-img {
          height: 40px;
        }
      }
      .socialAttractions {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-top: 2px solid #575757;
        border-bottom: 2px solid #575757;
        color: #000;
        font-family: Readex Pro Light !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.5px;
        letter-spacing: 0.2px;
        div a {
          color: #000 !important;
        }
      }
    }
    .copySec {
      margin-bottom: 33px;
    }
    .hc-credit-section {
      display: flex;
      justify-content: space-around;
      background: #05232e;
      color: #838383;
      font-family: Readex Pro Light !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 26.5px;
      letter-spacing: 0.2px;
      .hc-innsight-link {
        color: #838383;
        font-style: italic !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.5px;
        letter-spacing: 0.2px;
        text-decoration-line: underline;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .hc-subscription {
      .subscribe_wrapper {
        .subscribe-input-text {
          width: 60%;
        }
      }
    }
    .hc-otherDetails {
      .hc-site-links {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        .site1,
        .site2,
        .site3 {
          display: none;
        }
        .site4 {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
        }
      }
      .hc-social-section {
        .socialLinks {
          padding: 20px 0px;
          background: #fff;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          gap: 20px;
          .social-img {
            height: 50px;
          }
          .socialIconsStye {
            margin-top: 0px !important;
          }
        }

        .socialAttractions {
          grid-template-columns: repeat(2, 1fr);
          text-align: center;
          gap: 20px;
        }
      }
      .hc-credit-section {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 60px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .hc-subscription {
      .footerTitle {
        font-size: 30px;
      }
      .footerSubTitle {
        font-size: 40px;
      }
      .subscribe_wrapper {
        .subscribe-input-text {
          width: 100% !important;
        }
      }
    }
    .footer-hc-btn {
      width: 33%;
    }
    .hc-otherDetails {
      .hc-site-links {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        .site1,
        .site2,
        .site3 {
          display: none;
        }
        .site4 {
          display: flex;
          flex-direction: column !important;
          justify-content: center !important;
          align-items: center !important;
        }
      }
      .hc-social-section {
        .socialLinks {
          padding: 20px 0px;
          background: #fff;
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-direction: column;
          gap: 20px;
          .social-img {
            height: 50px;
          }
          .socialIconsStye {
            margin-top: 0px !important;
          }
        }

        .socialAttractions {
          grid-template-columns: repeat(1, 1fr) !important;
          text-align: center;
          gap: 20px;
        }
      }
      .hc-credit-section {
        .hc-custom-inn {
          text-align: center;
        }
      }
    }
  }
}
