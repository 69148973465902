.content-title a,
h1 {
  font-family: "Averia Serif Libre", cursive;
}


#root .maintanceWarmimg + .App .inner-header{top: 40px}

@media screen and (min-width: 992px) {
  #root .maintanceWarmimg + .App .inner-header{top: 60px}
}

@media screen and (max-width: 991px) {
  .maintance_content p {
    line-height: 20px;
}
  
}

.content-title a,
.content-title h2 {
  margin-bottom: 0 !important;
  text-align: left;
  color: #215f35;
  font-size: 20px;
  font-family: "Lato";

  letter-spacing: 0.9px;
}

.common_back_btn {
  border: 1px solid #215f35;
  letter-spacing: 0;
  color: #215f35;
  font-size: 24px;
  padding: 5px 52px !important;
  font-weight: 400;
  background-color: #fff;
}
.fs-innertitle1-48,
.title-two {
  color: #0ba167;
}
.title-two {
  font-size: 64px;
  letter-spacing: 3px;
  font-weight: 700;
  font-family: '  font-family: "Averia Serif Libre", cursive;';
}
.fs-13 {
  font-size: 13px;
}
.content-title a {
  cursor: pointer;
  font-size: 18px;
  color: #000 !important;
  font-weight: 500;
  text-decoration: none;
}
.fs-20 {
  font-size: 20px;
  font-family: lato;
}
.fs-14 {
  font-size: 14px;
}
.fs-innertitle1-48,
.fs-innertitle2-48 {
  font-size: 64px;
  letter-spacing: 3px;
  font-weight: 700;
  font-family: "Averia Serif Libre", cursive;

  bottom: 275px;
  transform: translate(-50%, -50%);
  left: 50%;
  position: relative;
}
.custom-height {
  height: 650px;
}
.bg-grey-color {
  background-color: #fafafa;
}
.underline-bdr {
  padding-bottom: 10px;
  text-decoration: none;
  border-bottom: 3px solid #000;
}
.lh-38 {
  line-height: 38px;
}
.fs-innertitle2-48 {
  color: #fafcfb;
}
.ls-1 {
  letter-spacing: 1px;
}
.btn-read-more {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(88 158 115 / 66%) !important;
  transition: 0.5s;
  position: relative;
}
.line-h-22 {
  line-height: 22px;
}
.fs-22 {
  font-size: 22px;
}
.link-color {
  color: #000 !important;
}
.button_bg:hover {
  color: #fff;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  transform: translateY(-5px);
}
.left-radius {
  border-bottom-left-radius: 30px;
  border-top-right-radius: 15px;
}
.left-right-radius {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.fs-16 {
  font-size: 16px;
  font-family: Lato;
}
.line-h-5 ul li {
  line-height: 2.2rem;
  font-size: 16px;
  font-weight: 600;
}
.ltr-none {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}
.ltr-border-none {
  border-top: 0;
  border-bottom: 0;
}

.cus-common-back-btn {
  background-color: #343a40 !important;
  border: none;
  color: #fff !important;
}
.cus-common-back-btn:hover {
  background-color: #1d2124 !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .inner-text-overlay h5 {
    padding-right: 6rem !important;
    padding-left: 5rem !important;
  }
  .banner-title-bg-color {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 15px 42px !important;
    font-family: "Averia Serif Libre", cursive;
    letter-spacing: 1px;
    position: relative;
    width: 100%;
    bottom: 7.6em;
    height: fit-content;
  }
}
.fs-xs-26 {
  color: #fff;
  font-size: 27px;
  margin-bottom: 0;
  text-align: left;
  font-family: "Averia Serif";
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none) {
  .fs-innertitle1-48 {
    font-size: 45px !important;
    position: relative;
    bottom: 200px !important;
  }
  .title-two {
    font-size: 50px;
    letter-spacing: 3px;
    color: #0ba167;
    font-weight: 700;
    font-family: '  font-family: "Averia Serif Libre", cursive;';
  }
}

@media only screen and (min-width: 360px) and (max-width: 414px) {
  .overlay-bg-layer {
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .fs-xs-21 {
    font-size: 21px;
  }

  .features-title {
    font-size: 30px !important;
    letter-spacing: 3px;
    color: #0ba167;
    font-weight: 700;
    font-family: "Averia Serif Libre", cursive;
  }
  .content-title {
    font-size: 18px !important;
    color: #686868;
    font-weight: 500;
    font-family: Lato;
  }
  .fs-innertitle2-48,
  .overview-title-one,
  .title-two {
    font-family: "Averia Serif Libre", cursive;
  }
  .overview-title-one {
    font-size: 22px !important;
    letter-spacing: 1px;
    color: #8e8e8e;
    font-weight: 700;
  }
  .ada-main-title {
    letter-spacing: 0 !important;
  }
  .ada-sub-title {
    font-size: 16px !important;
    letter-spacing: 1px !important;
  }
  .bg-ada-color {
    background-color: #0e3886;
    color: #fff;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .fs-innertitle1-48,
  .title-two {
    color: #0ba167;
    font-weight: 700;
  }
  .overview-title-three {
    font-size: 27px !important;
    letter-spacing: 2px;
    line-height: 30px !important;
  }
  .overview-title-two {
    font-size: 27px !important;
    letter-spacing: 0 !important;
    line-height: 45px !important;
  }
  .fs-innertitle1-48 {
    text-transform: uppercase;
    font-size: 27px;
    bottom: 65px;
    letter-spacing: 0;
  }
  .title-two {
    font-size: 30px;
    letter-spacing: 1px;
  }
  .fs-innertitle2-48 {
    font-size: 25px;
    letter-spacing: 3px;
    color: #fafcfb;
    font-weight: 700;
    position: relative;
    bottom: 45px;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  .banner-xs {
    object-fit: cover;
  }
  .banner-title-bg-color {
    font-size: 15px;
    letter-spacing: 1px;
  }
}

/* calender prev & next common css  */
.sdp--month-picker > button:first-child {
  background: url(../../../E/CommonAssets/images/LT.png);
  background-size: 170px;
  background-repeat: no-repeat;
  background-position: center;
}
.sdp--month-picker > button:first-child > svg {
  display: none;
}
.sdp--month-picker > button:first-child:hover {
  background-image: url(../../../E/CommonAssets/images/LT_hover.png) !important;
  background-size: 170px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
.sdp--month-picker > button:last-child {
  background: url(../../../E/CommonAssets/images/GT.png);
  background-size: 170px;
  background-repeat: no-repeat;
  background-position: center;
}
.sdp--month-picker > button:last-child > svg {
  display: none;
}
.sdp--month-picker > button:last-child:hover {
  background-image: url(../../../E/CommonAssets/images/GT_hover.png) !important;
  background-size: 170px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
/* calender prev & next common css  */
