$red: #e20001;
$black: #191919;

.hotellela {
  .container-fluid {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    .wrapper {
      padding: 0 0px;
      .home {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        p {
          font-size: 0.75rem;
          letter-spacing: 0.3rem;
          text-transform: uppercase;
          font-weight: 500;
        }
        h5 {
          margin-top: 260px;
          font-size: 24px;
          font-weight: 600;
          padding-right: 360px;
        }
      }
    }
  }

  .rct_wdgt .inner-header {
    top: 60px !important;
  }

  header {
    .custom_IH_wrapper {
      width: fit-content !important;
      height: fit-content !important;
      flex: 1;
    }
    .socialIconsStye {
      position: static;
      flex: 1;
    }
    .snip1226 {
      font-family: Mr Eaves Mod OT !important;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      padding: 0px;
      margin: 0px;
      width: 100%;
    }
    .headerContent {
      width: 100%;
    }
    .snip1226 * {
      box-sizing: border-box;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
    }

    .snip1226 li {
      display: inline-block;
      list-style: outside none none;

      overflow: hidden;
    }

    .snip1226 .NavLink {
      padding: 0.3em 0;
      color: #000;
      position: relative;
      display: inline-block;
      letter-spacing: 0.5px;
      font-size: 17px;
      margin: 0;
      text-decoration: none;
      padding: 12px 20px;
    }

    .snip1226 .NavLink:before,
    .snip1226 .NavLink:after {
      position: absolute;
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
    }

    .sidebar-tag {
      width: 95%;
      margin: 0 auto;
      white-space: nowrap;
    }
    .sidebar-tag.active {
      border-bottom: 2px solid #000;
      padding: 12px 20px !important;
    }
    .snip1226 .NavLink:after {
      padding: 0px 0 10px;
      bottom: 100%;
      left: 0;
      right: 0;
      content: attr(data-hover);
      white-space: nowrap;
      border-bottom: 2px solid #22543c;
      margin: 0 auto;
    }

    .snip1226 li:hover .NavLink {
      transform: translateY(100%);
    }

    .inner-header {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      height: auto;
      display: flex;
      justify-content: center !important;
      align-items: center !important;
      width: 100%;
      z-index: 8;
      .logo a {
        font-weight: 700;
        font-size: 1rem;
        text-decoration: none;
        color: #191919;
        z-index: 5;
        top: 2px;
      }
      .menu {
        z-index: 99999;
        button {
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
          font-size: 0.8rem;
          mix-blend-mode: difference;
        }
      }
    }
    .hamburger-menu {
      display: none;
      z-index: 9999;
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      .menu-secondary-background-color {
        background: #1a563a;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
      .menu-layer {
        position: relative;
        background: #1b1a1a;
        height: 100%;
        overflow: hidden;
        .menu-city-background {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0;
          background-size: cover;
          background-repeat: no-repeat;
          animation-name: backgroundEffect;
          animation-duration: 30s;
          animation-iteration-count: infinite;
        }
        .clickable {
          display: flex;
        }
        .hamburger-right {
          margin-top: 8px;
          margin-left: 10px;
          padding: 1px;
        }
        .hamburger-down {
          margin-left: 6px;
          margin-top: 6px;
        }
        .wrapper {
          position: relative;
          .menu-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            top: 100px;
            nav {
              display: block;
              ul {
                margin: 0;
                padding: 0;
                font-family: Mr Eaves Mod OT !important;
                li {
                  list-style: none;
                  font-size: 20px;
                  font-weight: 500;
                  cursor: pointer;
                  height: 55px;
                  overflow: hidden;
                  position: relative;
                  letter-spacing: 0.5px;
                  width: 100%;
                  color: #443e3e !important;
                  a {
                    position: absolute;
                    color: #fff;
                    text-decoration: none !important;
                    &:hover {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
            .info {
              color: #fff;
              width: 300px;
              h3 {
                font-size: 1.2rem;
                margin: 8px auto;
              }
              p {
                margin: 0 auto;
                font-size: 0.8rem;
              }
            }
          }
          .locations {
            position: absolute;
            bottom: -80px;
            color: #fff;
            margin-top: 16px;
            font-size: 0.8rem;
            font-weight: 600;
            span {
              cursor: pointer;
              &:first-child {
                margin-left: 64px;
              }
              font-weight: 400;
              margin: 0 32px;
              transition: 0.3s ease-in-out;
              &:hover {
                color: #fff;
                background: $black;
                padding: 8px 24px;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
  }

  @keyframes backgroundEffect {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 40% 10%;
    }
    50% {
      background-position: 0 10%;
    }
    75% {
      background-position: 10% 40%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  .headMenuLogoDiv {
    align-items: center;
    justify-content: flex-start;
    display: inline-grid;
    width: 100%;
  }

  .scrollBtn {
    position: fixed;
    width: 10%;
    left: 95%;
    bottom: 78px;
    height: 50px;
    font-size: 1rem;
    z-index: 1;
    cursor: pointer;
    color: #3e5aa9;
    background-color: transparent;
    border: none;
  }

  .scrollBtn svg {
    height: 50px;
    width: 50px;
    background-color: #dbd7d7;
    border-radius: 50%;
    padding: 10px;
  }

  .scrolBtnStyle {
    border: none;
    background: none;
    font-size: 0;
  }

  .logo {
    width: 100%;
    padding: 0px;
    left: 25%;
    max-width: 100% !important;
  }

  .reservDiv {
    z-index: 2;
  }

  .reservationBtn {
    float: right;
    z-index: 7;
  }

  .reservationBtn a {
    color: #fff;
    padding: 12px 15px;
    font-family: Mr Eaves Mod OT !important;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
  }

  .reservationBtn a:hover,
  .reservationBtn a:focus,
  .reservationBtn a:active {
    color: #fff;

    text-decoration: none;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .scrollBtn {
      left: 91%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .logo {
      margin-top: 15px !important;
      left: -1%;
    }
    .scrollBtn {
      width: 10%;
      left: 90% !important;
      bottom: 4%;
      height: 70px;
    }
    .headMenuLogoDiv {
      display: block;
      width: 100%;
    }
    header .inner-header .logo a {
      width: 100%;
      margin-left: 35px;
    }

    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li:hover {
      height: auto;
      overflow: auto;
      display: block;
      position: relative;
    }

    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li:hover a {
      position: relative;
      display: block;
    }

    .dropbtn:hover .dropdown-content {
      margin: 0;
    }
    .dropdown-tabview {
      margin-top: 10px;
    }
    .dropdown-content a {
      margin-left: 20px;
      font-size: 18px !important;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .logo {
      left: 15%;
    }
    header .inner-header {
      top: 25px;
    }
    .humburgMenuDiv {
      margin-right: 35px !important;
    }
    .container-fluid .wrapper {
      padding: 0 20px;
    }
    .menu {
      margin-right: 10px !important;
    }
    .dropdown-content a {
      font-size: 17px !important;
    }
  }
  @media screen and (max-width: 1199px) {
    header .socialIconsStye {
      display: none !important;
    }
  }

  // Mobile Responsiveness
  @media only screen and (max-width: 767px) {
    .wrapper {
      padding: 0px !important;
    }
    .scrollBtn {
      position: fixed;
      width: 10%;
      left: 86%;
      bottom: 85px;
    }
    .scrollBtn svg {
      height: 35px;
      width: 35px;
    }
    .headMenuLogoDiv {
      display: inline-block;
      width: 50%;
    }
    .logo {
      left: 0%;
      margin-top: 10px;
    }
    .dropdow-arrow-custom {
      display: none;
    }
    .logo a img {
      width: 72px;
      margin-top: 0px !important;
      margin-left: 0px;
      height: 72px;
      left: 4%;
    }
    .logo-caption {
      position: absolute;
      top: 73px !important;
      left: 3% !important;
      font-size: 7px !important;
    }
    header .inner-header .menu {
      margin-right: 2rem !important;
    }

    .container-mob-view {
      padding-top: 0px !important;
    }
    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li:hover {
      height: auto;
      overflow: auto;
      display: block;
      position: relative;
    }

    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li:hover a {
      position: relative;
      display: block;
    }

    .dropbtn:hover .dropdown-content {
      margin: 5px 0px;
    }
    .dropdown-tabview {
      margin-top: 0px;
    }
    .dropdown-content a {
      font-size: 16px !important;
    }
    .dropdown {
      position: relative;
      margin-top: -20px;
    }
  }

  .dropdown-content {
    display: none;
  }

  .dropdown-content .dropdown-tabview:hover {
    display: block;
  }

  .dropdown-content {
    display: grid;
  }
  .dropbtn:hover .dropdown-content {
    display: block;
    transition: opacity 0.6s ease-in-out;
    font-family: Mr Eaves Mod OT !important;
  }

  .dropdown-content a {
    position: relative !important;

    transition: 0.3s;
    padding: 14px 16px 0px 0px;
    text-decoration: none;
    font-size: 16px;
    color: #21553a;
    letter-spacing: 1px;
    line-height: 30px;
    text-align: center;
  }

  .dropdown-content a:hover {
    margin-bottom: 10px !important;
    display: block;
  }

  /****new************/

  @media screen and (min-width: 992px) {
    .click-about-us {
      line-height: 49px;
    }

    .menu-header-width {
      max-width: 1250px;
      margin: auto;
    }

    header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li {
      list-style: none;
      font-size: 25px !important;
      font-weight: 500;
      cursor: pointer;
      height: 75px !important;
      overflow: hidden;
      position: relative;
      letter-spacing: 0.5px;
      width: 100%;
      color: #443e3e !important;
    }
    .arrow-none {
      display: none !important;
    }
  }

  @media screen and (min-width: 1201px) and (max-width: 1399px) {
    .menuMainLink {
      line-height: 48px !important;
    }
    .nav_container ul li a {
      font-size: 30px !important;
      font-weight: 400;
      color: #fff;
      position: relative;
    }
  }

  @media screen and (min-width: 1200px) {
    .hidden-lg {
      display: none !important;
    }
  }

  @media screen and (min-width: 991px) and (max-width: 1199px) {
    .hidden-md {
      display: none !important;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .logo-caption {
      left: 9px !important;
    }
    .primary-carousel .carousel-indicators {
      bottom: 33% !important;
      z-index: 1;
    }
  }
  @media screen and (max-width: 767px) {
    .logo-mob img {
      position: absolute;
      top: 6px !important;
      width: 130px !important;
      left: 4px !important;
      margin: 5px 10px !important;
      z-index: 3;
      object-fit: fill;
    }
  }

  @media screen and (max-width: 991px) {
    .reservationBtn a {
      color: #fff;
      padding: 8px 6px;
      font-family: Mr Eaves Mod OT !important;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
      border: 1px solid;
      text-decoration: none;
      border-radius: 5px;
      .now_btn {
        padding-left: 8px;
      }
  }

    .menuReserDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      right: 37% !important;
      top: -12px;
      position: absolute;
    }
    .mobReservBtn a {
      color: #fff;
    }

    .logo-mob img {
      position: absolute;
      top: 18px;
      width: 200px;
      left: 15px;
      margin: 12px;
      z-index: 3;
      object-fit: fill;
    }

    header .inner-header {
      top: 5px;
    }
  }
  @media screen and (min-width: 1200px) {
  .social-container ul:first-child li{margin-right: 0px !important;}
  .social-container ul:last-child li{margin-left: 0px !important;}
}
  @media screen and (min-width: 2090px) {
    .cutom_IH_wrapper {
      position: absolute;
      height: auto;
      align-items: center;
      width: 100vw !important;
      left: 0;
      top: 0px;
      z-index: 8;
      display: flex;
      .inner-header {
        max-width: 2000px;
        position: relative;
      }
    }
  }

  .hm-reservationBtn{
    font-size: 16px !important;
    text-align: center;
  }
}
