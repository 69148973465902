/* wave error resolve  */
.maintance_content p {
  margin-bottom: 0px;
  font-size: 16px;
}
.maintanceWarmimg {
  position: relative;
  background: #ffeaeb;
  padding: 7px;
  color: #dc3642;
  font-size: 16px;
  font-weight: 600;
  z-index: 8;
  -webkit-box-shadow: 0px 5px 17px -11px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 17px -11px rgba(0, 0, 0, 0.75);
  border-bottom: 1px solid #ccc;
}

.customWaveErrorResolve {
  position: absolute !important;
  left: -9999px !important;
  top: 0px !important;
}
/* wave error resolve  */

.seo-content p {
  display: flex;
  justify-content: left;
  margin-left: 1.5rem;
}
.error_box {
  padding: 0px 0;
  border-radius: 7px;
  margin: 100px auto;
  border: 1px solid #74917d;
}
#ircw-container-div {
  z-index: 7;
}
main .section-padding {
  padding-top: 3%;
  padding-bottom: 3%;
  float: left !important;
}

.error_box img {
  height: 400px;
  object-fit: contain;
}

.page-container {
  position: relative;
  min-height: 100vh;
}
.content-wrap {
  padding-bottom: 2.5rem;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.goog-te-gadget-simple {
  background: #fff !important;
  color: #000;
  width: 150px;
  padding: 10px 10px !important;
}
.goog-te-gadget-simple span svg {
  margin-bottom: 2px !important;
}

@media screen and (min-width: 1200px) {
  body {
    max-width: 2000px !important;
    width: 100%;
    margin: 0 auto;
  }
  .smaller .checkAvail,
  .smaller .wrapCA {
    max-width: 2000px !important;
    width: 100%;
    margin: 0 auto;
  }
}

.Review-pop {
  display: none !important;
}

.google_translate_element {
  display: block !important;
}

#google_translate_element img {
  display: none;
}

.goog-te-combo:focus {
  outline: none !important;
}

.google_translate_element select {
  cursor: pointer !important;
}
.goog-te-gadget .goog-te-combo {
  margin: 3px 0;
  min-height: 47px;
  width: 157px;
  height: 40px;
  margin: 0px 0px 0px 5px !important;
  cursor: pointer;
}

.p-static-content {
  font-size: 16px;
  text-align: justify;
  font-family: "lato";
}
.fh-button,
.fh-button-red,
.fh-button-orange,
.fh-button-pink {
  font-family: "lato" !important;
  font-weight: 500 !important;
  letter-spacing: 0.025rem !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
}

.YFA_bannerImage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);
  z-index: 0;
}

.fh-button-orange:hover {
  background: #ff7929 !important;
  background: -moz-linear-gradient(to top, #ff7929 1%, #b84500 100%) !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ff7929),
    color-stop(100%, #b84500)
  ) !important;
  background: -webkit-linear-gradient(
    to top,
    #ff7929 1%,
    #b84500 100%
  ) !important;
  background: -ms-linear-gradient(to top, #ff7929 1%, #b84500 100%) !important;
  background: linear-gradient(to top, #ff7929 1%, #b84500 100%) !important;
  text-shadow: 0 -1px 1px #9e3c00 !important;
}

.border_green {
  border: 2px solid #215f35;
}

.heading-static {
  font-size: 22px;
  letter-spacing: 0.9px;
  color: #215f35;
  font-family: "Averia Serif Libre", cursive;
}

.fh-button-orange {
  background: #ff8d47;
  background: -moz-linear-gradient(to top, #ff8d47 1%, #d65100 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ff8d47),
    color-stop(100%, #d65100)
  );
  background: -webkit-linear-gradient(to top, #ff8d47 1%, #d65100 100%);
  background: -ms-linear-gradient(to top, #ff8d47 1%, #d65100 100%);
  background: linear-gradient(to top, #ff8d47 1%, #d65100 100%);
  text-shadow: 0 -1px 1px #a33d00 !important;
  color: #fff !important;
  box-shadow: inset 1px 2px 2px 0 #ffb98f, inset 1px -2px 2px 0 #c24900,
    inset -1px 0 1px 0 #ffac7a, inset -1px 0 1px 0 #d65100;
  border: 1px solid #f05a00 !important;
}

.add-to-home-banner {
  z-index: 999 !important;
  padding: 18px !important;
  font-weight: 500 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ircw_popclosedbutton {
    right: 7% !important;
  }

  .goog-te-gadget .goog-te-combo {
    margin: 3px 0;
    min-height: 47px;
    width: 157px;
    height: 40px;
    margin: 30px 0px 0px 5px !important;
    cursor: pointer;
  }

  #google_translate_element {
    position: relative !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ircw_popclosedbutton {
    right: 6% !important;
  }
}

@media only screen and (max-width: 767px) {
  .reservationDiv {
    display: block !important;
  }

  .menu_activated .reservationDiv {
    display: none !important;
  }

  .goog-te-gadget .goog-te-combo {
    margin: 0px 0px 0px 0px !important;
    width: 157px;
    height: 40px;
    cursor: pointer;
  }

  .goog-te-combo {
    border: 1px solid #000;
    text-align: center;
  }

  .ircw_popclosedbutton {
    right: 11% !important;
    top: 7px !important;
  }
}

.oops {
  font-size: 30px;
  position: relative;
  color: #1b5638;
}
.App + div > div {
  position: relative !important;
}

.links-icon li {
  display: block;
  width: 100%;
  margin: 0 4% 0 0;
  padding: 8px 0px;
  color: #1b5638;
  font-size: 18px;
}

@media screen and (max-width: 992px) {
  .innatsaratoga .toggle_component {
    width: 100%;
    display: block;
    justify-content: right;
    position: absolute;
    text-align: center;
    z-index: 99;
    padding: 0;
    height: 42px;
  }
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/***new common css later we can create new file***/
.btn-style {
  font-size: 17px;
  font-weight: 500;
  font-family: "Lato";
  padding: 10px 20px;
  background: linear-gradient(to right, #1a563a 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border: 1px solid #215f35;
}

.innatsaratoga .btn-style {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  background: linear-gradient(to right, #000000 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border: 1px solid #000;
}

.innatsaratoga .btn-style:hover {
  background-position: left bottom;
  color: white !important;
  text-decoration: none;
}

.hotellela .btn-style {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  background: linear-gradient(to right, #515d75 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border: 1px solid #000;
}

.santacruzcomfortinn .btn-style {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 20px;
  background: linear-gradient(to right, #00145f 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border: 1px solid #ff5f03;
}

.santacruzcomfortinn .btn-style:hover,
.hotellela .btn-style:hover {
  background-position: left bottom;
  color: white !important;
  text-decoration: none;
}

.hotellela .hotel-leela-btn {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  background: #4e5a73;
  width: fit-content;
  border: none;
  outline: none;
  color: #fff;
  font-family: Angie Sans Std !important;
  font-weight: 700;
  font-size: 16px;
}

.rm-cursor {
  cursor: none;
}

.btn-style:hover {
  background-position: left bottom;
  color: white !important;
  text-decoration: none;
}

.bg-loader {
  background: rgb(255, 255, 255);
  z-index: 999 !important;
  position: absolute;
  height: 100vh;
  width: 100%;
}

/***RCT media query***/
.body .container-rctwidgetsnn .datepicker {
  position: relative;
  left: 29px !important;
}

#ircw-container-div {
  display: none;
}

.hotelcurrent .btn-style {
  background: linear-gradient(to right, #017aa5 50%, white 50%);
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  border: 1px solid #017aa5;
}

.hotelcurrent .btn-style:hover {
  background-position: left bottom;
  color: white !important;
  text-decoration: none;
}
.innatsaratoga .cus_checkAvailWrapper {
  position: relative;
  display: flex;
  justify-content: center;
}
