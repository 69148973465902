.hotelcurrent .StartMenuStyle {
  @media screen and (min-width: 1200px) {
    .grid-container-list {
      margin-left: 40px;
      justify-content: space-evenly;
      align-items: center;
    }

    .dropdown-trigger {
      cursor: pointer;
    }

    .dropdown {
      position: relative;
      display: inline-block;
    }
    .dropdown-trigger {
      
      color: #fff;
      padding: 19px 5px;
      cursor: pointer;
    }
    .dropdown-content a {
      color: #333;
      padding: 12px 10px;
      text-decoration: none;
      display: block;
    }

    /* Change color of dropdown items on hover */
    .dropdown-content a:hover {
      background-color: #ddd;
      color: #000;
      margin-bottom: 0px !important;
    }
    .grid-list li {
      list-style: none !important;
    }

    .hotelcurrent .dropdown-trigger.active {
      padding: 0px !important;
      border-bottom: 2px solid #4e5a74;
    }
    .hotelcurrent .dropdown-trigger.active ~ .dropdown-content {
      top: 50px !important;
    }
    .dropbtn {
      background-color: #ddd;
      color: #000 !important;
      border: none !important;
    }
    .dropdown-content {
      background: rgba(0, 0, 0, 0.65);
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
      min-width: 160px;
      position: absolute;
      top: 76px;
      z-index: 1;
    }

    .gallery-content {
      margin-left: -30px !important;
    }
    ul {
      list-style-type: none;
    }

    li a {
      color: #fff;
      list-style-type: none;
    }

    .grid-list {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 40px;
      padding: 0;
      z-index: 3;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .grid-list {
    gap: 30px !important;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1399px) {
  .hotelcurrent .grid-container-list {
    margin-left: 60px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-mob {
    margin: 30px;
  }
  .header-mob {
    position: absolute;
    left: 0;
    z-index: 8;
  }
}
@media screen and (min-width: 1901px) {
  .hotelcurrent .grid-container-list {
    display: flex;
    margin-left: -300px;
  }
  .hotelcurrent .grid-list {
    gap: 80px;
  }
}
.hotelcurrent .desk-menu {
  -webkit-backdrop-filter: blur(4px);
  
  background: #fff;
  color: #fff;
  padding: 0 10px;
  position: absolute;
  z-index: 3;
  width: 100%;
  
}

@media screen and (min-width: 1200px) {
  .hotelcurrent .grid-list li {
    a {
      color: #000;
      font-family: Readex Pro Light !important;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
    }
  }
  .hotelcurrent .grid-list li .dropdown {
    a {
      color: hsl(0, 0%, 100%);
      font-family: Readex Pro Light !important;
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
    }
    .hotelcurrent .grid-list li .dropdown {
      a:hover {
        color: hsl(0, 0%, 1%);
        font-family: Readex Pro Light !important;
        font-size: 16px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
      }
      .hotelcurrent .dropdown-content {
        background-color: #3c3423;
        background: #3c3423;
      }
    }

    .dropdown-trigger {
      font-family: Readex Pro Light !important;
      font-weight: bold;
      color: #000;
    }
  }

  .hotelcurrent .grid-list {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px;
    padding: 10px;
    z-index: 3;
  }
}

@media screen and (min-width: 1900px) {
  .hotelcurrent .grid-container-list {
    margin-left: 190px;
  }
  .hotelcurrent .grid-list {
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 80px;
    padding: 10px;
    z-index: 3;
  }
}

.hotelcurrent .grid-container-list .active {
  text-decoration: none;
  border-bottom: 2px solid #4e5a74;
}

.hotelcurrent .dropdown-content a:hover {
  background-color: #ddd;
  color: #000 !important;
  margin-bottom: 0px !important;
}
