.sidebar-tag {
  padding: 12px 32px 12px 32px;
}

.ywg-logo {
  width: 120px;
  height: 120px;
  position: relative;
  z-index: 2;
}

.hotelcurrent .menuReserDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 95px;
  top: 0px;
  position: absolute;
}

.humburgMenuDiv {
  margin-left: 30px !important;
  z-index: 7;
}

.hotelcurrent .smaller .ywg-logo {
  width: 100%;
  height: 38px;
  margin-top: 20px;
  object-fit: fill;
}

.smaller .menuReserDiv {
  right: unset;
  left: 2%;
}

.smaller .logo-caption {
  font-size: 10px;
  bottom: -9px;
  left: 0px;
  display: none;
}

.logo-caption {
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  z-index: 2;
  color: #fff;
  left: 5px;
}

.link-color a:hover {
  border: 1px solid #1d7c4b;
  color: #1d7c4b;
  padding: 12px 31px 12px 31px;
  text-decoration: none;
}

.nav-fs-21.active {
  border: 1px solid #1d7c4b;
  color: #1d7c4b;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ywg-logo {
    margin-top: 0;
    width: 80px;
    height: 80px;
  }

  .menuReserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 7%;
    top: 20%;
    position: absolute;
  }

  .hotelcurrent .menuReserDiv {
    top: 8%;
    right: 30px !important;
  }
  .logo-caption {
    left: 4%;
    top: 100%;
    color: #fff;
    font-size: 10px;
  }

  .humburgMenuDiv {
    margin-right: 40px;
    margin-left: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  .humburgMenuDiv {
    margin-right: 0px;
    margin-left: 20px !important;
  }

  .menuReserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 7% !important;
    top: -12px;
    position: absolute;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1599px) {
  .hotelcurrent .smaller .ywg-logo {
    left: -67px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ywg-logo {
    margin-top: 0px;
  }
  .menuReserDiv {
    top: unset;
    right: 55px;
  }

  .menuReserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 68px;
    top: 20%;
    position: absolute;
  }

  .logo-caption {
    color: #fff;
    top: unset;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hotelcurrent .menuReserDiv {
    top: 0px !important;
  }

  @-moz-document url-prefix() {
    .hotelcurrent .menuReserDiv {
      right: 15px;
      top: -12px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .hotelcurrent .menuReserDiv {
    right: 0px;
    top: -10px;
  }
}

@media screen and (min-width: 1901px) {
  .hotelcurrent .smaller .ywg-logo {
    width: 100%;
    height: 55px;
    margin-top: 15px;
    object-fit: fill;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .hotelcurrent .inner-header .menuReserDiv {
    margin: 13px 10px !important;
  }

  .hotelcurrent .inner-header {
    top: 0px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1399px) {
  @supports (-ms-ime-align: auto) {
    .hotelcurrent .menuReserDiv {
      right: 15px !important;
      top: 0px !important;
    }
  }
}

@media screen and (min-width: 1400px) and (max-width: 1440px) {
  .hotelcurrent .menuReserDiv {
    right: 80px;
    top: 0px;
  }
}
