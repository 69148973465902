:root {
  --color-facebook: #3b5999;
  --color-instagram: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  --color-linkedin: #0072b1;
  --color-googlePlus: #d74937;
}

.socialIconsStye {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .connect-saratoga {
    // font-family: Angie Sans Std !important;
    font-family: Angie Sans Std Demi !important;
    font-size: 40px;
    font-weight: 600;
  }
  .social-container {
    .empty-div {
      text-indent: -999px;
      visibility: hidden;
      margin-top: -1100px;
    }
  }
}

@media screen and (max-width: 767px) {
  @media screen and (max-width: 992px) {
    .hotellela .socialIconsStye {
      flex-direction: column;
      margin-top: 50px !important;
      gap: 40px;
      .social-container {
        ul {
          padding: 0rem;
          gap: 42px !important;
        }
      }
    }
  }

  .hotellela .socialIconsStye {
    flex-direction: column;
    margin-top: 0px !important;
    gap: 40px;
    .social-container {
      ul {
        padding: 0rem;
        gap: 4px !important;
      }
    }
  }
}

.hotellela .social-container ul {
  padding: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 30px;
  li {
    list-style: none;
    display: inline-block;
    margin: 10px;
    padding: 0;
    position: relative;
    .cus_tooltip {
      color: white;
      position: absolute;
      z-index: 200;
      top: -55px !important;
      left: -38px !important;
      width: 120px;
      left: 0px;
      padding: 10px;
      border-radius: 10px;
      display: none;
      font-family: Angie Sans Std !important;
      letter-spacing: 1px;
    }
    &:nth-child(1) {
      .cus_tooltip {
        background-color: var(--color-facebook);
        svg {
          fill: var(--color-facebook);
        }
      }
    }
    &:nth-child(2) {
      .cus_tooltip {
        background: linear-gradient(
          45deg,
          #fdf497 0%,
          #fdf497 5%,
          #fd5949 45%,
          #d6249f 60%,
          #285aeb 90%
        );
        svg {
          fill: #fb7e73;
          z-index: -1;
        }
      }
    }
    &:nth-child(3) {
      .cus_tooltip {
        background-color: var(--color-linkedin);
        svg {
          fill: var(--color-linkedin);
        }
      }
    }
    &:nth-child(4) {
      .cus_tooltip {
        background-color: var(--color-googlePlus);
        svg {
          fill: var(--color-googlePlus);
        }
      }
    }
    &:hover {
      .cus_tooltip {
        display: grid;
        place-items: center;
        svg {
          position: absolute;
          top: 40px;
        }
      }
    }
    a {
      position: relative;
      text-decoration: none;
      display: grid !important;
      width: 40px;
      height: 40px;
      justify-content: center;
      text-align: center;
      line-height: 50px;
      font-size: 25px;
      background: initial;
      transition: all 0.5s ease-in-out;
      z-index: 1;
      overflow: hidden;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 50%;
      background: #fff;
      .icon {
        position: relative;
        color: #fff;
        transition: 0.5s;
        z-index: 3;
        fill: #000;
        font-size: 27px;
      }
      &:hover .icon {
        fill: #fff;
        transform: rotateY(360deg);
      }
      &:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: #f00;
        transition: 0.5s;
        z-index: 2;
      }
      &:hover:before {
        top: 0;
      }
    }
    &:nth-child(1) a:before {
      background: var(--color-facebook);
    }
    &:nth-child(2) a:before {
      background: var(--color-instagram);
      // instagram
    }
    &:nth-child(3) a:before {
      background: var(--color-linkedin);
    }
    &:nth-child(4) a:before {
      background: var(--color-googlePlus);
    }
  }
}


@media screen and (min-width:1200px) and (max-width:1299px){
  .hotellela .social-container .list-unstyled{
    gap: 5px !important;
  }
}