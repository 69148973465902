:root {
  --color-facebook: #3b5999;
  --color-instagram: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  --color-linkedin: #0072b1;
  --color-googlePlus: #d74937;
  --color-yelp: #ff1a1a;
  --color-tripadvisor: #00af87;
}
.hotelcurrent {
  .socialIconsStye {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .connect-saratoga {
      font-family: Readex Pro Light !important;
      font-size: 40px;
      font-weight: 600;
    }
    .social-container {
      .empty-div {
        text-indent: -999px;
        visibility: hidden;
        margin-top: -1100px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0px;
        gap: 0px;
        li {
          list-style: none;
          display: inline-block;
          margin: 10px;
          padding: 0;
          position: relative;
          .cus_tooltip {
            color: white;
            position: absolute;
            z-index: 200;
            top: -55px !important;
            left: -25px !important;
            width: 120px;
            left: 0px;
            padding: 10px;
            border-radius: 10px;
            display: none;
            font-family: Readex Pro Light !important;
            letter-spacing: 1px;
          }
          &:nth-child(1) {
            .cus_tooltip {
              background-color: var(--color-facebook);
              svg {
                fill: var(--color-facebook);
              }
            }
          }

          &:nth-child(2) {
            .cus_tooltip {
              background-color: var(--color-linkedin);
              svg {
                fill: var(--color-linkedin);
              }
            }
          }
          &:nth-child(3) {
            .cus_tooltip {
              background-color: var(--color-yelp);
              svg {
                fill: var(--color-yelp);
              }
            }
          }
          &:nth-child(4) {
            .cus_tooltip {
              background-color: var(--color-tripadvisor);
              svg {
                fill: var(--color-tripadvisor);
              }
            }
          }
          &:hover {
            .cus_tooltip {
              display: grid;
              place-items: center;
              svg {
                position: absolute;
                top: 40px;
              }
            }
          }
          a {
            position: relative;
            text-decoration: none;
            display: grid !important;
            width: 60px;
            height: 60px;
            justify-content: center;
            text-align: center;
            line-height: 50px;
            font-size: 25px;
            background: initial;
            transition: all 0.5s ease-in-out;
            z-index: 1;
            overflow: hidden;
            border: 1px solid #9cb7c1;
            color: #fff;
            border-radius: 50%;
            .icon {
              position: relative;
              color: #fff;
              transition: 0.5s;
              z-index: 3;
              fill: #017aa5;
              font-size: 35px;
            }
            &:hover .icon {
              fill: #fff;
              transform: rotateY(360deg);
            }
            &:before {
              content: "";
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              height: 100%;
              background: #f00;
              transition: 0.5s;
              z-index: 2;
            }
            &:hover:before {
              top: 0;
            }
          }
          &:nth-child(1) a:before {
            background: var(--color-facebook);
          }
          &:nth-child(2) a:before {
            background: var(--color-linkedin);
          }
          &:nth-child(3) a:before {
            background: var(--color-yelp);
          }
          &:nth-child(4) a:before {
            background: var(--color-tripadvisor);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .socialIconsStye {
    flex-direction: column;
    margin-top: 50px !important;
    gap: 40px;
    .social-container {
      ul {
        padding: 0rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .socialIconsStye {
    margin-top: 30px !important;
  }
}
