.santacruzcomfortinn {
  .grecaptcha-badge {
    display: none !important;
  }
  .Footer-Style-SantaCruzComfortInn {
    background-image: url("../assets/images/footer-bg-comfortInn.svg");
    p,
    a {
      padding: 0;
      margin: 0;
      font-weight: 200;
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    .first_section {
      min-height: 100px;
    }
    .comfortInnTitle {
      border: 1px solid #00145f;
      border-radius: 0px 10px 10px 0;
      margin-left: 0px;
      padding-left: 0px;
      border-left: 0px;
      width: fit-content !important;
      padding-right: 1rem;
      legend {
        font-weight: lighter;
        padding-right: 15px;
        text-transform: capitalize;
        font-size: 18px;
        width: fit-content;
        margin-bottom: 0px;
        color: #ffffff;
      }
      h3 {
        text-transform: uppercase;
        font-size: 46px;
        margin-bottom: 0px;
        padding-bottom: 15px;
        line-height: 35px;
        background: linear-gradient(to bottom, #bfcdff, #ffffff);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
    .Accbtn {
      background: #0563ae !important;
      border-radius: 0;
      color: #fff !important;
      font-size: 15px !important;
      justify-content: space-evenly;
      width: 140px;
      height: 45px;
      padding: 12px 10px !important;
      border-radius: 5px;
      a {
        color: #fff;
      }
    }
    .lang-btn {
      height: fit-content;
      .goog-te-gadget-simple {
        border-radius: 5px;
      }
    }
    .subscribe_wrapper {
      .newsletter-form {
        position: relative;
        input#subscriber_email {
          padding: 1.5rem 0.75rem;
          border-radius: 10px;
          background-color: #7a83a4;
          color: #fff;
          &::placeholder {
            color: #fff;
          }
        }
        .footer-conmfort-btn {
          font-family: "Red Rose" !important;
          font-size: 16px;
          line-height: 35px;
          font-weight: 500;
          background: url("../assets/Icons/btnIcon.svg") 5px 85px no-repeat
            #ff5f03;
          background-size: 190% 45%;
          background-repeat: no-repeat;
          background-position: -10px 11px;
          letter-spacing: 2px;
          border: 0px solid #000;
          border-radius: 10px;
          padding: 0px 26px 0px 10px !important;
          text-transform: capitalize;
          z-index: 3 !important;
          text-align: left;
          color: #fff !important;
          position: absolute;
          top: 10%;
          right: 6px;
          height: 80%;
          &:hover {
            background-color: #00145f;
            outline: 1px solid #fff;
          }
        }
      }
    }
    .second_section {
      .logo-section {
        border-radius: 15px;
        p a {
          color: #00145f;
          font-weight: 400;
        }
        .hotel-links {
          gap: 15px;
        }
        .logo {
          img {
            height: 120px;
          }
        }
      }
      .copyright-section {
        font-size: 12px;
      }
    }
    .footer_paSec a {
      width: fit-content;
    }
    @media screen and (max-width: 767px) {
      .subsCr {
        font-size: 24px !important;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      .comfortInnTitle {
        legend {
          font-size: 16px;
        }
        h3 {
          font-size: 25px;
          padding-bottom: 10px;
          line-height: 20px;
        }
      }
    }
  }
  .copySec1 {
    margin-bottom: 30px;
  }
  
  .roomBtnStyle2:hover,
  h2 a,
  .About .about-title-two,
  .About .features-title {
    color: #00145f !important;
    font-family: "Red Rose" !important;
  }
  .slick-slide.slick-active.slick-current .individualCard .slideCardLogoSec svg,
  .About .btn-style:hover,
  .slick-slide.slick-active.slick-current .individualCard .slideCardLogoSec {
    background: #00145f !important;
  }
}
