.sidebar-tag {
  padding: 12px 32px 12px 32px;
}

.ywg-logo {
  /* margin-top: 50px; */
  width: 120px;
  height: 120px;
  position: relative;
  z-index: 2;
}

.humburgMenuDiv {
  margin-left: 30px !important;
  z-index: 7;
}

.yosemitewestgate .smaller .ywg-logo {
  width: 85px;
  height: 85px;
  margin-top: 0px;
}

.smaller .menuReserDiv {
  right: unset;
  left: 2%;
}

.smaller .logo-caption {
  font-size: 10px;
  bottom: -9px;
  left: 0px;
  display: none;
}

.logo-caption {
  position: absolute;
  bottom: -15px;
  font-size: 11px;
  z-index: 2;
  color: #fff;
  left: 5px;
}

.link-color a:hover {
  border: 1px solid #1d7c4b;
  color: #1d7c4b;
  padding: 12px 31px 12px 31px;
  text-decoration: none;
}

.nav-fs-21.active {
  border: 1px solid #1d7c4b;
  color: #1d7c4b;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ywg-logo {
    margin-top: 0;
    width: 80px;
    height: 80px;
  }

  .menuReserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 7%;
    top: 20%;
    position: absolute;
  }

  .menuReserDiv {
    top: 8%;
    right: 30px;
  }
  .logo-caption {
    left: 4%;
    top: 100%;
    color: #fff;
    font-size: 10px;
  }

  .humburgMenuDiv {
    margin-right: 40px;
    margin-left: 20px !important;
  }
}

@media screen and (min-width: 1199px) {
  .yosemitewestgate .menuReserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 7%;
    top: 10px;
    position: absolute;
  }
}

@media screen and (max-width: 767px) {
  .humburgMenuDiv {
    margin-right: 0px;
    margin-left: 20px !important;
  }

  .yosemitewestgate .menuReserDiv {
    display: flex;
    align-items: center;
    right: 7%;
    top: 5%;
    /* position: static; */
    justify-content: left;
    width: 125px;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1599px) {
  .smaller .ywg-logo {
    left: -25px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ywg-logo {
    margin-top: 0px;
  }
  .menuReserDiv {
    top: unset;
    right: 55px;
  }

  .menuReserDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    right: 68px;
    top: 20%;
    position: absolute;
  }

  .logo-caption {
    color: #fff;
    top: unset;
  }
}

@media screen and (min-width: 1200px) {
  .App.smaller .reservDiv .ReservationBtn .hm-reservationBtn {
    display: none !important;
  }
  .App.smaller .headMenuLogoDiv {
    display: none !important;
  }
}
