.hotellela {
  .StartMenuStyle {
    flex: 6;
    .dropbtn {
      background-color: #ddd;
      color: #000 !important;
      border: none !important;
    }
    @media screen and (min-width: 1200px) {
      .desk-menu .grid-container-list .grid-list li .active {
        font-weight: 600 !important;
      }
      
      .grid-container-list {
        margin-left: 0px;
      }

      .dropdown-trigger {
        cursor: pointer;
      }
      .navSocial {
        position: absolute;
        right: 0px;
      }
      .dropdown {
        position: relative;
        display: inline-block;
      }
      .dropdown-trigger {
        color: #fff;
        padding: 19px 5px;
        cursor: pointer;
      }

      .dropdown-content a {
        color: #333;
        padding: 12px 10px;
        text-decoration: none;
        display: block;
      }

      .dropdown-content a:hover {
        background-color: #ddd;
        color: #000;
        margin-bottom: 0px !important;
      }
      .grid-list li {
        list-style: none !important;
      }

      .hotellela.dropdown-trigger.active {
        padding: 0px !important;
        border-bottom: 2px solid #4e5a74;
      }
      .hotellela .dropdown-trigger.active ~ .dropdown-content {
        top: 50px !important;
      }
      .dropbtn {
        background-color: #ddd;
        color: #000 !important;
        border: none !important;
      }
      .dropdown-content {
        background: rgba(0, 0, 0, 0.65);
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        min-width: 160px;
        position: absolute;
        top: 76px;
        z-index: 2;
      }

      .gallery-content {
        margin-left: -30px !important;
      }
      ul {
        list-style-type: none;
        list-style: none;
      }

      li a {
        // color: #fff;
        color: #5f646a;
        list-style: none;
      }

      .grid-list {
        display: flex;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        padding: 0;
        z-index: 3;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .logo-mob img {
      width: 100%;
      height: 100px;
    }

    .header-mob {
      position: absolute;
      left: 0;
      z-index: 8;
    }
  }

  @media screen and (min-width: 1901px) {
    .hotellela .grid-container-list {
      display: flex;
    }
  }

  .hotellela.desk-menu {
    -webkit-backdrop-filter: blur(4px);
    background: #fff;
    color: #fff;
    z-index: 3;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    .hotellela .grid-list li {
      a {
        color: #000 !important;
        font-family: Mr Eaves Mod OT !important;
        font-size: 15px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
      }
    }
    .hotellela .grid-list li .dropdown {
      a {
        color: hsl(0, 0%, 100%) !important;
        font-family: Mr Eaves Mod OT !important;
        font-size: 15px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        &:hover {
          color: hsl(0, 0%, 1%) !important;
        }
      }
      .dropdown-content {
        background-color: #3c3423;
        .active {
          color: hsl(0, 0%, 1%) !important;
        }
      }

      .dropdown-trigger {
        font-family: Mr Eaves Mod OT !important;
        font-weight: bold;
        color: #000;
        font-size: 15px !important;
      }
    }

    .hotellela.grid-list {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      padding: 10px !important;
      z-index: 3;
    }
  }
  @media screen and (min-width: 1900px) {
    .hotellela .grid-container-list {
      margin-left: 190px;
    }
    .hotellela .grid-list {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      padding: 10px;
      z-index: 3;
    }
  }
  .hotellela.grid-container-list .grid-list {
    width: 100%;
  }
  .hotellela .grid-container-list .active {
    text-decoration: none;
    border-bottom: 2px solid #4e5a74;
  }

  .hotellela .dropdown-content a:hover {
    background-color: #ddd;
    color: #000 !important;
    margin-bottom: 0px !important;
  }
}
