.santacruzcomfortinn {
  .StartMenuStyle {
    flex: 6;
    .dropbtn {
      background-color: #ddd;
      color: #fff !important;
      border: none !important;
    }
    @media screen and (min-width: 1200px) {
      .grid-container-list {
        margin-left: 0px;
      }

      .dropdown-trigger {
        cursor: pointer;
      }
      .navSocial {
        position: absolute;
        right: 0px;
      }
      .dropdown {
        position: relative;
        display: inline-block;
      }
      .dropdown-trigger {
        color: #fff;
        padding: 19px 5px;
        cursor: pointer;
      }

      .dropdown-content a {
        color: #333;
        padding: 12px 10px;
        text-decoration: none;
        display: block;
      }

      .dropdown-content a:hover {
        background-color: #ddd;
        color: #fff;
        margin-bottom: 0px !important;
      }
      .grid-list li {
        list-style: none !important;
        padding: 0px 20px;
      }
      .custome-menu-css {
        display: flex;
        align-items: baseline;
      }

      .grid-list.d-flex.justify-content-around {
        justify-content: space-between !important;
      }
      .dropdown-trigger.active {
        padding: 0px !important;
        border-bottom: 2px solid #ff5f03;
      }
      .dropdown-trigger.active ~ .dropdown-content {
        top: 50px !important;
      }
      .dropbtn {
        background-color: #ddd;
        color: #fff !important;
        border: none !important;
      }
      .dropdown-content {
        background: rgba(0, 0, 0, 0.65);
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        min-width: 160px;
        position: absolute;
        top: 76px;
        z-index: 2;
      }

      .gallery-content {
        margin-left: -30px !important;
      }
      ul {
        list-style-type: none;
      }

      li a {
        color: #fff;
        list-style-type: none;
      }

      .grid-list {
        display: flex;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

        padding: 0;
        z-index: 3;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .logo-mob img {
      width: 100%;
      height: 100px;
    }

    .header-mob {
      position: absolute;
      left: 0;
      z-index: 8;
    }
  }

  @media screen and (min-width: 1901px) {
    .grid-container-list {
      display: flex;
    }
  }

  .desk-menu {
    background: #00145f;
    color: #fff;
    z-index: 3;
    width: 100%;
  }

  @media screen and (min-width: 1200px) {
    .grid-list li {
      a {
        color: #fff !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .grid-list li .dropdown {
      a {
        color: hsl(0, 0%, 100%) !important;
        font-family: "Red Rose" !important;
        font-size: 15px;
        font-style: normal;
        font-weight: bold;
        line-height: normal;
        &:hover {
          color: hsl(0, 0%, 1%) !important;
        }
      }
      .dropdown-content {
        background-color: #00145f;
        .active {
          color: hsl(0, 0%, 1%) !important;
        }
      }

      .dropdown-trigger {
        font-weight: 400;
        color: #fff;
        font-size: 15px !important;
      }
    }

    .grid-list {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      padding: 10px !important;
      z-index: 3;
    }
  }
  @media screen and (min-width: 1900px) {
    .grid-container-list {
      margin-left: 190px;
    }
    .grid-list {
      display: flex;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      padding: 10px;
      z-index: 3;
    }
  }
  .grid-container-list .grid-list {
    width: 100%;
  }
  .grid-container-list {
    .active {
      text-decoration: none;
      border-bottom: 2px solid #ff5f03;
    }
  }

  .dropdown-content a:hover {
    background-color: #ddd;
    color: #fff !important;
    margin-bottom: 0px !important;
  }
  .Main-header-links {
    background-color: #00145f;
    margin-left: 8%;
  }

  @media screen and (max-width: 1199px) {
    .hamburger-react {
      color: #fff;
    }
  }
}
