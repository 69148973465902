.cus-position {
  position: absolute;
  bottom: 0px;
  z-index: 2;
  width: 100%;
  .cus-banner-title-bg-color {
    font-family: "Averia Serif Libre", cursive;
    letter-spacing: 0.5px;
    font-size: 22px;
    text-align: center;
    width: 100%;
    div {
      background: rgba(0, 0, 0, 0.6);
      padding: 10px 15px;
      width: 100%;
    }
  }
}

.inner-text-overlay {
  position: relative;
  bottom: 0px;
  text-align: center !important;
  margin: 0px auto;
  left: 0;
  right: 0;
  letter-spacing: 2px;
}
// Yosemite Westgate

title {
  text-transform: lowercase;
}

.bg-gradient {
  .bg-picture-tag {
    object-fit: cover !important;
  }
  &::after {
    content: "";
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.2) 30%,
      rgba(0, 0, 0, 0.1) 60%,
      rgba(0, 0, 0, 0.1) 75%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
}
.placholder-img {
  height: 620px;
}

.underline-btm {
  padding-bottom: 10px;
}
.h1Seperator {
  margin: 0 auto !important;
  height: 6px;
  width: 35%;
  background-image: linear-gradient(45deg, white, #0c502e, white);
}

.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

.infoLayer {
  background: #ede6da;
  border-top: 0.1px solid rgba(90, 90, 90, 0.1882352941);
  position: relative;
  top: 20px;
}
.infoLayer li a {
  color: #1b5538 !important;
  // padding: 7px 30px !important;
  padding: 6px 30px 25px !important;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 14px;
}
.infoLayer li a span:hover {
  border-bottom: 2px solid #18520f;
  padding-bottom: 2px;
}

.info-header {
  display: none;
}

.primary-title {
  font-size: 32px;
  color: #215f35;
  display: inline-block;
  letter-spacing: 0;
  font-weight: 500;
  font-family: "Averia Serif Libre";
  margin: 32px 0px 3px 0px;
  word-break: break-word;
}

.displayCA {
  display: flex;
  flex-direction: column;
  position: relative;
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .cus-position {
    z-index: 1;
  }
}
#showMe {
  animation: cssAnimation 0s 1s forwards;
  visibility: hidden;
}

@keyframes cssAnimation {
  to {
    visibility: visible;
  }
}
/* dummy code */
.banner-title-bg-color {
  left: 0px;
  right: 0px;
  bottom: 120px;
  padding: 12px 24px;
  font-family: "Averia Serif Libre", cursive;
  letter-spacing: 0.5px;
  font-size: 22px;
  text-align: center;
  z-index: 1;
}

.overlay-bg-layer::before {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.45);
  height: 100%;
  width: 100%;
  content: "";
  display: block;
}

.page-title {
  font-size: 26.9px;
  color: #215f35;
  margin-top: 0;
  margin-bottom: 20px;
  border-bottom: 3px solid #215f35;
  display: inline-block;
  padding-bottom: 7px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 350;
}

@media only screen and (min-width: 1199px) {
  .banner-title-bg-color span {
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 15px;
  }
  .test-heigh {
    height: 600px;
  }

  .fade-in {
    opacity: 0;
    transition: opacity 0.8s ease-in-out;
  }

  .fade-in.active {
    opacity: 1;
  }
}

/* Portrait*/
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .inner-text-overlay {
    position: absolute;
    top: 85%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .inner-text-overlay {
    position: absolute;
    top: 80%;
  }
  .primary-title {
    word-break: break-word;
  }
}

@media (min-width: 1200px) and (max-width: 1280px) {
  .primary-title {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .smaller .info-header {
    display: block !important;
    position: fixed;
    bottom: 0px;
    z-index: 9;
  }
  .banner-title-bg-color {
    left: 0px;
    right: 0px;
    bottom: 165px;
    padding: 12px 24px;
    font-family: "Averia Serif Libre", cursive;
    letter-spacing: 0.5px;
    font-size: 22px;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .inner-text-overlay {
    position: absolute;
    top: 85%;
    left: 0%;
  }

  .slideCardLogo {
    width: 90% !important;
    margin-left: 5px !important;
  }

  .slick-next {
    right: 0px !important;
  }

  .h1Seperator {
    width: 40%;
    margin-top: 10px !important;
  }

  .mob-text-center {
    position: relative;
  }

  .primary-title {
    font-size: 23px;
    padding: 0px;
    margin: 33px 2px 0px 0px;
    width: 96%;
    text-align: center;
    word-break: break-word;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .primary-title {
    font-size: 25px;
    text-align: center;
    color: #215f35;
    display: inline-block;
    letter-spacing: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "Averia Serif Libre";
    width: 93%;
    word-break: break-word;
  }
}

@media screen and (min-width: 1200px) {
  .yosemitewestgate {
    .h1Seperator {
      margin: 5px auto 30px auto !important;
    }

    .about.aboutTittle,
    .primary-title.text-center.underline-btm,
    h1#info1 {
      font-size: 30px !important;
      text-transform: uppercase !important;
    }
    .banner-title-bg-color {
      left: 0px;
      right: 0px;
      bottom: 111px;
      padding: 12px 24px;
      font-family: "Averia Serif Libre", cursive;
      letter-spacing: 0.5px;
      font-size: 22px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 1199px) {
  h1#info1 + .h1Seperator {
    margin-bottom: 20px !important;
  }
}

.innatsaratoga {
  .h1Seperator {
    background: url(../../CommonAssets/images/the-inn-at-saratoga-text-divider.png)
      no-repeat scroll center center;
    content: "";
    height: 26px;
    width: 100%;
    margin-bottom: 15px !important;
  }
  .primary-title {
    font-size: 32px;
    color: #090c0a;
    display: inline-block;
    letter-spacing: 0;
    font-weight: 500;
    font-family: Classic Roman Std !important;
    margin: 32px 0px 3px 0px;
    word-break: break-word;
    text-transform: capitalize;
  }
  .infoLayer {
    background: #e1ce8d;
  }

  .infoLayer li a {
    color: #090c0a;
  }
}
.hotelcurrent {
  .primary-title {
    color: #017aa5 !important;
  }
  .infoLayer li a {
    color: #017aa5 !important;
  }
  .h1Seperator {
    margin: 0 auto !important;
    background: url(../../CommonAssets/Icons/CurrentSelector.svg) no-repeat
      scroll center center;
    content: "";
    height: 39px;
    width: 100%;
  }
}
.hotellela {
  .primary-title {
    font-size: 40px !important;
    color: #000;
    display: inline-block;
    letter-spacing: 0;
    font-weight: 400;
    // font-family: Angie Sans Std !important;
    font-family: Angie Sans Std Demi !important;
    margin: 32px 0px 3px 0px;
    word-break: break-word;
    text-transform: capitalize;
    font-style: normal;
    line-height: normal;
  }
  .h1Seperator {
    margin: 0 auto !important;
    height: 6px;
    width: 35%;
    background-image: linear-gradient(45deg, white, #4e5a73, white);
    opacity: 0;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .primary-title {
      font-size: 40px !important;
    }
  }
  @media screen and (max-width: 767px) {
    .primary-title {
      font-size: 30px !important;
    }
  }
}

.santacruzcomfortinn {
  .primary-title {
    font-size: 32px !important;
    color: #00145f;
    display: inline-block;
    letter-spacing: 0;
    font-weight: 400;
    font-family: "Red Rose" !important;
    margin: 32px 0px 3px 0px;
    word-break: break-word;
    text-transform: capitalize;
    font-style: normal;
    line-height: normal;
  }
  .h1Seperator {
    margin: 0 auto !important;
    height: 6px;
    width: 35%;
    background-image: linear-gradient(45deg, white, #ff5f03, white);
    opacity: 0;
  }
  .primary-title {
    color: #00145f !important;
  }
  .infoLayer li a {
    color: #00145f !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .primary-title {
      font-size: 30px !important;
    }
  }
  @media screen and (max-width: 767px) {
    .primary-title {
      font-size: 23px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1280px) {
    .primary-title {
      font-size: 30px !important;
    }
  }
  @media screen and (max-width: 1199px) {
    h1#info1 + .h1Seperator {
      margin-bottom: 0px !important;
    }
  }
}
