.santacruzcomfortinn {
  .reservationBtn {
    position: relative;
  }
  .sidenav {
    background-color: #000;
  }
  .hm-reservationBtn::before {
    content: "";
    position: absolute;
    height: 27px;
    width: 50px;
    background-color: #00145f;
    top: -3px;
    z-index: -1;
    width: 104%;
    left: -3px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #fff;
    border-bottom: 1px solid #00145f;
  }
  .hm-reservationBtn {
    background: #ff5f03 !important;
    color: #fff;
    text-decoration: none;
    border-radius: 10px;
    font-family: "Red Rose" !important;
    font-size: 16px;
    padding: 8px 13px;
    text-transform: uppercase;
    border: none;
    position: absolute;
    height: 49px;
    right: 210px;
    top: -10px;
    position: static !important;
  }
  .sidenav {
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #00145f !important;
    overflow-x: hidden;
    transition: 0.5s;
  }
  .CAActiveOnBtn .bg-color-datepicker {
    display: block;
  }
  @media screen and (max-width: 767px) {
    .mob-hm-reservation {
      color: #ffffff;
      position: relative;
      right: -6px;
    }

    .for-xs-mob {
      margin: 20px 0px;
    }
    .mob-hm-reservation {
      color: #ffffff;
      position: relative;
      right: -6px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .for-xs-mob {
      margin: 20px 8px;
    }
  }
  @media screen and (min-width: 1201px) and (max-width: 1399px) {
    @supports (-ms-ime-align: auto) {
      .hm-reservationBtn {
        margin: 0px 0px !important;
        font-size: 16px;
        width: 130px;
        position: inherit;
      }
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1299px) {
    .hm-reservationBtn {
      position: absolute;
      left: 895px !important;
      top: -10px;
    }
    .hm-reservationBtn {
      margin: 0px 0px !important;
      font-size: 16px;
      width: 130px;
      position: inherit;
    }
    .hm-reservationBtn {
      background: #3c3423;
      color: #fff;
      text-decoration: none;
      font-family: "Red Rose" !important;
      font-size: 16px;
      width: 158px;
      height: 58px;
      letter-spacing: 1px;
      padding: 8px 13px;
      text-transform: uppercase;
      border: none;
      position: absolute;
      left: -20px !important;
      top: 4px;
    }
  }
}

.ReservationBtn {
  .menu_activated {
    overflow: hidden;
  }

  .cust-mrgn {
    margin-top: 15px;
  }

  @media screen and (min-width: 360px) {
    .bg-color-datepicker {
      border: 2px solid #fff;
    }

    .book-direct-title {
      font-size: 48px;
      font-family: "Red Rose" !important;
      letter-spacing: 1px;
      padding: 0px 0px 20px 0px;
    }

    .sidenav a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 25px;
      color: #818181;
      display: block;
      transition: 0.3s;
    }

    .sidenav a:hover {
      color: #f1f1f1;
    }

    .sidenav .closebtn {
      position: absolute;
      top: -13px;
      top: -14px;
      right: 50px;
      right: 20px;
      font-size: 111px;
      font-weight: 100;
      color: #fff;
      cursor: pointer;
    }
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .mob-hm-reservation {
      color: #ffffff;
      position: relative;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cust-mrgn {
      margin-top: 25px;
    }
  }

  @media screen and (min-width: 991px) {
    .mainCheckAvail .date-position {
      position: absolute;
      left: 0px;
      top: 84px;
    }
  }

  @media screen and (max-width: 767px) {
    .close-container {
      position: relative;
      margin: auto;
      width: 50px;
      height: 50px;
      margin-top: 28px;
      cursor: pointer;
      left: 40% !important;
    }

    .sidenav .closebtn {
      position: absolute;
      top: -30px;
      right: 20px;
      font-size: 90px;
      font-weight: 100;
      color: #fff;
      cursor: pointer;
    }

    .book-direct-title {
      font-size: 30px !important;
      font-family: "Red Rose" !important;
      letter-spacing: 1px;
      padding: 0px 0px 20px 0px;
    }
    .justify-ca {
      justify-content: center;
      padding: 7px !important;
    }
  }

  .close-container {
    position: relative;
    margin: auto;
    width: 50px;
    height: 50px;
    margin-top: 20px;
    cursor: pointer;
    left: 46.8%;
    top: 4%;
  }

  .leftright {
    height: 4px;
    width: 50px;
    position: absolute;
    margin-top: 24px;
    background-color: #fdfbfa;
    border-radius: 2px;
    transform: rotate(45deg);
    transition: all 0.3s ease-in;
  }

  .rightleft {
    height: 4px;
    width: 50px;
    position: absolute;
    margin-top: 24px;
    background-color: #fdfbfa;
    border-radius: 2px;
    transform: rotate(-45deg);
    transition: all 0.3s ease-in;
  }

  label {
    color: white;
    font-family: "Red Rose" !important;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease-in;
    opacity: 0;
  }

  .close-container:hover .leftright {
    transform: rotate(-45deg);
    background-color: #f8f8f8;
  }

  .close-container:hover .rightleft {
    transform: rotate(45deg);
    background-color: #fffbfc;
  }

  .close-container:hover label {
    opacity: 1;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1299px) {
  .for-mac-device {
    position: relative;
  }
}
